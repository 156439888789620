import React from "react"
import TemplatePageGuide from "@components/pageWebinar/template"
import { Helmet } from "react-helmet"
import data from "@components/pageWebinar/webinar/webinar1/dataPage"


const IndexPage = ({ location }) => (
  <div className="fnd">
    <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        Nuevas funcionalidades de WhatsApp Business API en el 2025
        [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/webinar/gupshup/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Descubre cómo WhatsApp Business API actualiza sus herramientas para mejorar la conexión con tus clientes y optimizar la gestión de mensajes. "
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Nuevas funcionalidades de WhatsApp Business API en el 2025"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/webinar/gupshup/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Descubre cómo WhatsApp Business API actualiza sus herramientas para mejorar la conexión con tus clientes y optimizar la gestión de mensajes. "
      />
    </Helmet>
    <TemplatePageGuide
      location={location}
      landing="webinar1"
      data={data}
    />
  </div>
)

export default IndexPage
